export default {
  namespaced: true,
  state () {
    return {
      alert: null
    }
  },

  mutations: {
    setAlert (state, alert) {
      state.alert = alert
    },
    clearAlert (state) {
      state.alert = null
    }
  },

  actions: {
    setAlert ({ commit }, alert) {
      commit('setAlert', alert)
      setTimeout(() => {
        commit('clearAlert')
      }, 5000)
    }
  },

  getters: {
    getAlert (state) {
      return state.alert
    }
  }
}
