<template>
  <v-footer app color="success" dark>
    <span>Copyright &copy; {{ new Date().getFullYear() }} - Masarka Student Club</span>
  </v-footer>
</template>

<script>
export default {
  name: 'admin-footer'
}
</script>
