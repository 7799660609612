const ALERT_CODES = {
  // 'EMAIL_NOT_FOUND': 'User with given email was not found',
  // 'INVALID_PASSWORD': 'Incorrect password',
  login: { message: 'Please login the system', type: 'info' },
  unauthorized: { message: 'Unauthorized', type: 'warning' }
}

export function getAlertByCode (code) {
  return ALERT_CODES[code] ? ALERT_CODES[code] : { message: 'Unknown error', type: 'error' }
}
