export default [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Profile'),
    meta: {
      layout: 'main',
      auth: true,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('@/views/Profile'),
    meta: {
      layout: 'main',
      auth: true,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/devices',
    name: 'UserDevices',
    component: () => import('@/views/Devices'),
    meta: {
      layout: 'main',
      auth: true,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/reservations',
    name: 'UserReservations',
    component: () => import('@/views/Reservations'),
    meta: {
      layout: 'main',
      auth: true,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/payments',
    name: 'UserPayments',
    component: () => import('@/views/Payments'),
    meta: {
      layout: 'main',
      auth: true,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  }
]
