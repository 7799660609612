import axios from '@/axios'
import router from '@/router'
import store from '@/store'

const TOKEN_KEY = 'token'
const USER_KEY = 'user'

export default {
  namespaced: true,
  state () {
    return {
      token: localStorage.getItem(TOKEN_KEY),
      user: localStorage.getItem(USER_KEY)
    }
  },

  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem(TOKEN_KEY, token)
    },
    setUser (state, user) {
      state.user = JSON.stringify(user)
      localStorage.setItem(USER_KEY, JSON.stringify(user))
    },
    clearToken (state) {
      state.token = null
      localStorage.removeItem(TOKEN_KEY)
    },
    clearUser (state) {
      state.user = null
      localStorage.removeItem(USER_KEY)
    },
    logout (state) {
      state.token = null
      state.user = null
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(USER_KEY)
      router.push('/login')
    }
  },

  actions: {
    async login ({ commit, dispatch }, payload) {
      try {
        const { data } = await axios.post('/auth/login', { ...payload })
        commit('setUser', data)
        commit('setToken', data.accessToken)
        await router.push('/')
      } catch (e) {
        await store.dispatch('alert/setAlert', {
          message: 'Wrong credentials.',
          type: 'error'
        })
      }
    },

    async forgotPassword ({ dispatch }, email) {
      try {
        const response = await axios.post('/auth/forgot_password', { email: email })

        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Request has been successfully sent',
            type: 'success'
          })
        }
      } catch (e) {
        await store.dispatch('alert/setAlert', {
          message: 'E-mail not found',
          type: 'error'
        })
      }
    },

    async resetPassword ({ dispatch }, password) {
      try {
        const response = await axios.put('/account/profile', { password: password },
          { headers: { Authorization: 'Bearer ' + router.currentRoute.query.token } })

        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Password successfully updated',
            type: 'success'
          })
          await router.push('/login')
        } else {
          await store.dispatch('alert/setAlert', {
            message: 'Incorrect access token',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  },

  getters: {
    getToken (state) {
      return state.token
    },
    getUser (state) {
      return JSON.parse(state.user)
    },
    getUserRoles (_, getters) {
      try {
        const user = getters.getUser
        return user.roles
      } catch (e) {
        return []
      }
    },
    isAuthenticated (_, getters) {
      return !!getters.getToken
    }
  }
}
