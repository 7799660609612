import axios from '@/axios'
import store from '@/store'
import { getHeaders } from '@/axios/headers'

export default {
  namespaced: true,
  actions: {
    async fetchAllUsers ({ dispatch }) {
      try {
        const response = await axios.get('/users', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async fetchUserById ({ dispatch }, id) {
      try {
        const response = await axios.get(`/users/${id}`, { headers: getHeaders() })
        return response.status !== 200 ? null : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async createUser ({ dispatch }, user) {
      try {
        const response = await axios.post('/users', user, { headers: getHeaders() })
        if (response.status === 201) {
          await store.dispatch('alert/setAlert', {
            message: 'New user successfully created',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async updateUser ({ dispatch }, user) {
      try {
        const response = await axios.put(`/users/${user.id}`, user, { headers: getHeaders() })
        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Item successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async deleteUser ({ dispatch }, id) {
      try {
        const response = await axios.delete(`/users/${id}`, { headers: getHeaders() })
        if (response.status === 204) {
          await store.dispatch('alert/setAlert', {
            message: 'User successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async resetUserPassword ({ dispatch }, id) {
      try {
        const response = await axios.get(`/users/${id}/reset_password`, { headers: getHeaders() })
        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'User password has been successfully reset',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async downloadUserRegistrationForm ({ dispatch }, id) {
      try {
        const response = await axios.get(`/users/${id}/pdf`, { headers: getHeaders(), responseType: 'blob' })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${id}.pdf`)
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    }
  },
  getters: {
  }
}
