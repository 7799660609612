import axios from '@/axios'
import store from '@/store'
import { getHeaders } from '@/axios/headers'

export default {
  namespaced: true,
  actions: {
    async fetchRegistrations ({ dispatch }) {
      try {
        const response = await axios.get('/registrations', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async fetchRegistrationById ({ dispatch }, id) {
      try {
        const response = await axios.get(`/registrations/${id}`, { headers: getHeaders() })
        return response.status !== 200 ? null : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async createRegistration ({ dispatch }, registration) {
      try {
        const response = await axios.post('/registrations', registration, { headers: getHeaders() })
        if (response.status === 201) {
          await store.dispatch('alert/setAlert', {
            message: 'New registration successfully created',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async updateRegistration ({ dispatch }, registration) {
      try {
        const response = await axios.put(`/registrations/${registration.id}`, registration, { headers: getHeaders() })
        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Item successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async deleteRegistration ({ dispatch }, id) {
      try {
        const response = await axios.delete(`/registrations/${id}`, { headers: getHeaders() })
        return response.status !== 200 ? null : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    }
  },
  getters: {
  }
}
