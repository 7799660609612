<template>
  <v-navigation-drawer
    width="275px"
    v-model="isOpen"
    app
    light
    prominent
  >

    <v-list-item two-line v-if="user">
<!--      <v-list-item-avatar>-->
<!--        <v-icon>mdi-account</v-icon>-->
<!--      </v-list-item-avatar>-->

      <v-list-item-content>
        <v-list-item-title>{{ user.name }} {{ user.surname }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>

      <!--        <v-subheader>User Panel</v-subheader>-->

      <v-list-item-group color="success">

        <v-list-item to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/devices">
          <v-list-item-icon>
            <v-icon>mdi-devices</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/payments">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-check-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Payments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/reservations">
          <v-list-item-icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reservations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="warning" @click="$store.commit('auth/logout')">
          <v-icon>mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'TheSidebar',
  data () {
    return {
      isOpen: true,
      user: null
    }
  },
  async mounted () {
    document.title = 'Profile | Masarka Student Club'
    this.user = await this.$store.getters['auth/getUser']
  }
}
</script>
