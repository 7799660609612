import Vue from 'vue'
import Vuex from 'vuex'

import account from '@/store/modules/account.module'
import auth from '@/store/modules/auth.module'
import alert from '@/store/modules/alert.module'
import user from '@/store/modules/user.module'
import registration from '@/store/modules/registration.module'
import rest from '@/store/modules/rest.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account, auth, alert, user, registration, rest
  }
})
