<template>
  <v-app-bar
    app
    color="success"
    dark
  >
    <v-app-bar-nav-icon @click="$emit('toggleSidebar')"></v-app-bar-nav-icon>

    <img :src="logo" style="max-height: 100%">

    <v-spacer></v-spacer>

    <v-btn v-if="showAdminPanelButton" to="/admin" text>
      <span class="mr-2">Admin panel</span>
      <v-icon>mdi-tools</v-icon>
    </v-btn>

    <v-btn v-if="isModeratorOnly" to="/admin/reservations" text>
      <span class="mr-2">Admin panel</span>
      <v-icon>mdi-tools</v-icon>
    </v-btn>

    <v-btn to="/" text fab>
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn @click="$store.commit('auth/logout')" text fab>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import logo from '@/assets/mk-logo-white.svg'

export default {
  name: 'main-navbar',
  emits: ['toggleSidebar'],
  data () {
    return {
      logo
    }
  },
  computed: {
    isModeratorOnly () {
      return this.$store.getters['auth/getUser'].roles.includes('ROLE_MODERATOR') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_ADMIN') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_REGISTRAR') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_CHAIRMAN') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_BOARD_MEMBER')
    },

    showAdminPanelButton () {
      if (this.$store.getters['auth/isAuthenticated']) {
        return this.$store.getters['auth/getUser'].roles.includes('ROLE_ADMIN') ||
          this.$store.getters['auth/getUser'].roles.includes('ROLE_REGISTRAR') ||
          this.$store.getters['auth/getUser'].roles.includes('ROLE_CHAIRMAN') ||
          this.$store.getters['auth/getUser'].roles.includes('ROLE_BOARD_MEMBER')
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
