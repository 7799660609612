<template>
  <v-app>

    <TheSidebar ref="sidebar" />
    <TheNavbar @toggleSidebar="$refs.sidebar.isOpen = !$refs.sidebar.isOpen" />

    <v-main>
      <v-container v-if="$store.getters['alert/getAlert']">
        <v-alert
          border="left"
          dismissible
          elevation="5"
          :type="$store.getters['alert/getAlert'].type"
        >{{ $store.getters['alert/getAlert'].message }}</v-alert>
      </v-container>

      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <TheFooter />
  </v-app>
</template>

<script>
import TheSidebar from '@/components/ui/main/TheSidebar'
import TheNavbar from '@/components/ui/main/TheNavbar'
import TheFooter from '@/components/ui/main/TheFooter'

export default {
  name: 'MainLayout',
  components: { TheSidebar, TheNavbar, TheFooter }
}
</script>

<style scoped>

</style>
