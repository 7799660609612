import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import auth from '@/router/routes/auth'
import admin from '@/router/routes/admin'
import main from '@/router/routes/main'

Vue.use(VueRouter)

const routes = [].concat(auth).concat(admin).concat(main)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth
  const isAuthenticated = store.getters['auth/isAuthenticated']

  // allow access if doesn't require authentication
  if (!requireAuth) {
    next()
    return
  }

  // redirect to login page if not authenticated
  if (requireAuth && !isAuthenticated) {
    next('/login?alert=login')
    return
  }

  // get authenticated user roles
  const userRoles = store.getters['auth/getUser'].roles

  // check if user is authorized (check if at least one user roles is allowed to access given view)
  if (userRoles.filter(value => to.meta.roles.includes(value)).length > 0) {
    next()
  } else {
    store.dispatch('alert/setAlert', {
      message: 'Unauthorized - You don\'t have permission to view this page.',
      type: 'warning'
    })
  }
})

export default router
