<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-app-bar-nav-icon @click="$emit('toggleSidebar')"></v-app-bar-nav-icon>

    <img :src="logo" style="max-height: 100%">

    <v-spacer></v-spacer>

    <v-btn to="/" text fab>
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn @click="$store.commit('auth/logout')" text fab>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import logo from '@/assets/mk-logo-white.svg'

export default {
  name: 'admin-navbar',
  emits: ['toggleSidebar'],
  data () {
    return {
      logo
    }
  }
}
</script>

<style scoped>

</style>
