<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import AuthLayout from '@/layouts/AuthLayout'
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'

export default {

  name: 'App',

  components: {
    AdminLayout, AuthLayout, EmptyLayout, MainLayout
  },

  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  }

}
</script>
