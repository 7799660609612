<template>
  <v-app>
    <v-main style="background-color: #f6f8fa">
      <v-container v-if="$store.getters['alert/getAlert']">
        <v-alert
          border="left"
          dismissible
          elevation="5"
          :type="$store.getters['alert/getAlert'].type"
        >{{ $store.getters['alert/getAlert'].message }}</v-alert>
      </v-container>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { getAlertByCode } from '@/utils/alerts'

export default {
  mounted () {
    if (this.$route.query.alert) {
      this.$store.dispatch('alert/setAlert', getAlertByCode(this.$route.query.alert))
    }
  }
}
</script>
