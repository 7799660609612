export default [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/registrations/RegistrationsTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/registrations',
    name: 'RegistrationsTable',
    component: () => import('@/views/admin/registrations/RegistrationsTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/registrations/edit',
    name: 'RegistrationEdit',
    component: () => import('@/views/admin/registrations/RegistrationEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/users',
    name: 'UsersTable',
    component: () => import('@/views/admin/users/UsersTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/users/edit',
    name: 'UserEdit',
    component: () => import('@/views/admin/users/UserEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/users/new',
    name: 'UserNew',
    component: () => import('@/views/admin/users/UserNew'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/reservation_items',
    name: 'ReservationItemsTable',
    component: () => import('@/views/admin/reservation-items/ReservationItemsTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/reservation_items/edit',
    name: 'ReservationItemEdit',
    component: () => import('@/views/admin/reservation-items/ReservationItemEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/reservation_items/new',
    name: 'ReservationItemNew',
    component: () => import('@/views/admin/reservation-items/ReservationItemNew'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/reservations',
    name: 'ReservationsTable',
    component: () => import('@/views/admin/reservations/ReservationsTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/reservations/edit',
    name: 'ReservationEdit',
    component: () => import('@/views/admin/reservations/ReservationEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/payments',
    name: 'PaymentsTable',
    component: () => import('@/views/admin/payments/PaymentsTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/payments/edit',
    name: 'PaymentEdit',
    component: () => import('@/views/admin/payments/PaymentEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/devices',
    name: 'DevicesTable',
    component: () => import('@/views/admin/devices/DevicesTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/devices/edit',
    name: 'DeviceEdit',
    component: () => import('@/views/admin/devices/DeviceEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/devices/new',
    name: 'DeviceNew',
    component: () => import('@/views/admin/devices/DeviceNew'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/bans',
    name: 'BansTable',
    component: () => import('@/views/admin/bans/BansTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/admin/bans/edit',
    name: 'BanEdit',
    component: () => import('@/views/admin/bans/BanEdit'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/bans/new',
    name: 'BanNew',
    component: () => import('@/views/admin/bans/BanNew'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN'
      ]
    }
  },
  {
    path: '/admin/users/roles',
    name: 'UserRolesTable',
    component: () => import('@/views/admin/users/UserRolesTable'),
    meta: {
      layout: 'admin',
      auth: true,
      roles: [
        'ROLE_CHAIRMAN'
      ]
    }
  }
]
