import store from '@/store'
import axios from '@/axios'
import { getHeaders } from '@/axios/headers'

export default {
  namespaced: true,
  actions: {
    async fetchUserProfile () {
      try {
        const response = await axios.get('/account/profile', { headers: getHeaders() })
        return response.status !== 200 ? null : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async updateUserProfile ({ dispatch }, userProfile) {
      try {
        const response = await axios.put('/account/profile',
          { email: userProfile.email, roomNumber: userProfile.roomNumber }, { headers: getHeaders() })

        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Profile successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async updateUserPassword ({ dispatch }, password) {
      try {
        const response = await axios.put('/account/profile',
          { password: password }, { headers: getHeaders() })

        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Password successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserReservations ({ dispatch }) {
      try {
        const response = await axios.get('/account/reservations', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async createUserReservation ({ dispatch }, reservation) {
      try {
        const response = await axios.post('/account/reservations', reservation, { headers: getHeaders() })
        if (response.status === 201) {
          await store.dispatch('alert/setAlert', {
            message: 'New reservation successfully created',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async deleteUserReservation ({ dispatch }, id) {
      try {
        const response = await axios.delete(`/account/reservations/${id}`, { headers: getHeaders() })
        if (response.status === 204) {
          await store.dispatch('alert/setAlert', {
            message: 'Item successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchActiveReservationItems ({ dispatch }) {
      try {
        const response = await axios.get('/reservation_items/active', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async fetchReservationsByReservationItem ({ dispatch }, id) {
      try {
        const response = await axios.get(`/reservations/reservation_item/${id}`, { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserDevices ({ dispatch }) {
      try {
        const response = await axios.get('/account/devices', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async createUserDevice ({ dispatch }, device) {
      try {
        const response = await axios.post('/account/devices', device, { headers: getHeaders() })
        if (response.status === 201) {
          await store.dispatch('alert/setAlert', {
            message: 'New device successfully created',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async updateUserDevice ({ dispatch }, device) {
      try {
        const response = await axios.put(`/account/devices/${device.id}`, device, { headers: getHeaders() })
        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Device successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async deleteUserDevice ({ dispatch }, id) {
      try {
        const response = await axios.delete(`/account/devices/${id}`, { headers: getHeaders() })
        if (response.status === 204) {
          await store.dispatch('alert/setAlert', {
            message: 'Device successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserPayments ({ dispatch }) {
      try {
        const response = await axios.get('/account/payments', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserBans ({ dispatch }) {
      try {
        const response = await axios.get('/account/bans', { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e)
      }
    }
  }
}
