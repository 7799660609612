export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    meta: {
      layout: 'auth',
      auth: false,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/auth/Registration'),
    meta: {
      layout: 'auth',
      auth: false,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: {
      layout: 'auth',
      auth: false,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword'),
    meta: {
      layout: 'auth',
      auth: false,
      roles: [
        'ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRAR', 'ROLE_MODERATOR', 'ROLE_CHAIRMAN', 'ROLE_BOARD_MEMBER'
      ]
    }
  }
]
