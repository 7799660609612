import axios from 'axios'
import store from '@/store'
import router from '@/router'

const requestAxios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL
})

requestAxios.interceptors.response.use(null, error => {
  // console.log(error)
  if (error.response.status === 401) {
    store.commit('auth/clearToken')
    store.commit('auth/clearUser')
    router.push('/login?alert=login')
  }
  if (error.response.status === 403) {
    store.dispatch('alert/setAlert', {
      message: 'Unauthorized - You don\'t have permission to perform this action.',
      type: 'warning'
    })
  }
  if (error.response.status === 500) {
    store.dispatch('alert/setAlert',
      {
        message: error.response.data.message ? error.response.data.message : 'Unknown error',
        type: error.response.data.type ? error.response.data.type : 'error'
      })
  }
  return Promise.reject(error)
})

export default requestAxios
