<template>
  <v-navigation-drawer
    width="275px"
    v-model="isOpen"
    app
    light
    prominent
  >

    <v-list-item two-line v-if="user">
<!--      <v-list-item-avatar>-->
<!--        <v-icon>mdi-account</v-icon>-->
<!--      </v-list-item-avatar>-->

      <v-list-item-content>
        <v-list-item-title>{{ user.name }} {{ user.surname }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>

      <v-list-item-group color="primary">

        <v-list-item to="/" exact>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/registrations" v-if="!isModeratorOnly">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Registrations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/users" exact v-if="!isModeratorOnly">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/users/roles" v-if="!isModeratorOnly">
          <v-list-item-icon>
            <v-icon>mdi-account-key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>User Roles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/devices" v-if="!isModeratorOnly">
          <v-list-item-icon>
            <v-icon>mdi-devices</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/payments" v-if="!isModeratorOnly">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-check-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Payments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/reservations">
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reservations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/reservation_items">
          <v-list-item-icon>
            <v-icon>mdi-calendar-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reservation items</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/bans">
          <v-list-item-icon>
            <v-icon>mdi-account-cancel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bans</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

<!--        <v-list-group-->
<!--          prepend-icon="mdi-calendar"-->
<!--          no-action-->
<!--        >-->
<!--          <template v-slot:activator>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Reservation system</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </template>-->

<!--          <v-list-item to="/admin/reservations">-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Reservations</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item to="/admin/reservation_items">-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>Items</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--        </v-list-group>-->

      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="warning" @click="$store.commit('auth/logout')">
          <v-icon>mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'TheSidebar',
  data () {
    return {
      isOpen: true,
      user: null
    }
  },

  computed: {
    isModeratorOnly () {
      return this.$store.getters['auth/getUser'].roles.includes('ROLE_MODERATOR') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_ADMIN') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_REGISTRAR') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_CHAIRMAN') &&
        !this.$store.getters['auth/getUser'].roles.includes('ROLE_BOARD_MEMBER')
    }
  },

  async mounted () {
    document.title = 'Profile | Masarka Student Club'
    this.user = await this.$store.getters['auth/getUser']
  }
}
</script>

<style scoped>

</style>
