import axios from '@/axios'
import store from '@/store'
import { getHeaders } from '@/axios/headers'

export default {
  namespaced: true,
  actions: {
    async fetchAllItems ({ dispatch }, payload) {
      try {
        const response = await axios.get(`/${payload.path}`, { headers: getHeaders() })
        return response.status !== 200 ? Array(0) : response.data
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async fetchItemById ({ dispatch }, payload) {
      try {
        const response = await axios.get(`/${payload.path}/${payload.id}`, { headers: getHeaders() })
        return response.status !== 200 ? null : response.data
      } catch (e) {
        console.log(e)
      }
    },

    async createItem ({ dispatch }, payload) {
      try {
        const response = await axios.post(`/${payload.path}`, payload.item, { headers: getHeaders() })
        if (response.status === 201) {
          await store.dispatch('alert/setAlert', {
            message: 'New item successfully created',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async updateItem ({ dispatch }, payload) {
      try {
        const response = await axios.put(`/${payload.path}/${payload.item.id}`, payload.item, { headers: getHeaders() })
        if (response.status === 200) {
          await store.dispatch('alert/setAlert', {
            message: 'Item successfully updated',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e.response.data.error.message)
      }
    },

    async deleteItem ({ dispatch }, payload) {
      try {
        const response = await axios.delete(`/${payload.path}/${payload.id}`, { headers: getHeaders() })
        if (response.status === 204) {
          await store.dispatch('alert/setAlert', {
            message: 'Item successfully deleted',
            type: 'success'
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {
  }
}
